@use './stylesheet/abstract' as *
$loader-bar-height: 2px
//region Fonts
@font-face
	font-family: "MontRegular"
	src: url("../public/fonts/Fontfabric - Mont Regular.otf")


@font-face
	font-family: "MontHeavy"
	src: url("../public/fonts/Fontfabric - Mont Heavy.otf")


html
	font-family: MontRegular, serif
//endregion
//region Default
html
	//region Hide scrollbar
	/* Hide scrollbar for IE, Edge and Firefox */
	-ms-overflow-style: none
	scrollbar-width: none
	/* Hide scrollbar for Chrome, Safari and Opera */
	&::-webkit-scrollbar
		display: none
	//endregion

body
	margin: 0
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif
	-webkit-font-smoothing: antialiased
	-moz-osx-font-smoothing: grayscale

	font-size: 16px


code
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace
//endregion
//region Overlay
.overlay
	z-index: 10

	position: fixed
	inset: 0
	max-height: 100vh

	color: white

	pointer-events: none

	.top
		display: flex
		flex-flow: row nowrap
		justify-content: space-between
		align-items: center

		padding: 2rem 6rem

		position: relative
		z-index: 1

		.gax-logo
			pointer-events: all

			img
				max-height: 1.5rem

		.links
			display: flex
			flex-direction: row
			gap: 2rem

			.button
				padding: .5em 1em .3em

				font-size: 20px
				line-height: 18px
				font-family: MontHeavy, serif

				pointer-events: all

		@include zi-media
			padding: 1rem 2rem

		@include zi-media('<=', mobile)
			padding: 1rem 1rem

			.gax-logo
				img
					max-height: 1.3rem

			.links
				gap: .5rem

				.button
					font-size: .9rem

	.middle
		position: fixed
		inset: 0
		max-height: 100vh

		display: flex
		flex-direction: column
		justify-content: center
		align-items: center
		gap: 4rem

		transition: transform .5s ease

		background: #080808

		pointer-events: all

		.content
			height: 36px
			display: flex
			flex-direction: row
			gap: 5px
			justify-content: center
			align-items: center

			svg
				height: 100%
				width: auto

		.linear_background
			position: absolute
			max-height: 100vh
			height: 100vh
			width: 100vw
			max-width: 100vw
			z-index: -1

			overflow: hidden

			> *
				object-fit: cover
				object-position: center center
				height: 100%
				width: 100%

				//region Animation
				animation: scale-animation 15s infinite, rotate-animation 120s infinite
				@keyframes scale-animation
					0%
						scale: 1.5
					50%
						scale: .8
					100%
						scale: 1.5

				@keyframes rotate-animation
					0%
						transform: rotate(0)
					100%
						transform: rotate(360deg)
				//endregion




		.loading_container
			display: flex
			flex-direction: row
			gap: .5rem
			font-size: 2rem
			font-family: MontHeavy, serif

			.loading_bar
				width: 20rem
				height: $loader-bar-height
				border-radius: 10px
				background: rgba(255, 255, 255, .5)
				position: relative

				.loading_bar_inner
					position: absolute
					min-width: $loader-bar-height
					top: 0
					left: 0
					bottom: 0
					right: 0
					background: white
					border-radius: 10px
					transition: width 1s ease

			#loading_percent
				min-width: 2.5rem
				text-align: right


	&.middle-hidden
		.middle
			opacity: 0
			transition: opacity 1s ease



	.bottom
		display: flex
		flex-direction: column
		gap: 1rem

		justify-content: center
		align-items: center

		position: fixed
		bottom: 2rem
		left: 0
		right: 0

		color: white
		fill: white

		//region Animation
		animation: scroll-animation 5s infinite alternate
		@keyframes scroll-animation
			0%
				transform: translateY(0)
			40%
				transform: translateY(0)
			50%
				transform: translateY(1rem)
			60%
				transform: translateY(0)
			100%
				transform: translateY(0)
		//endregion

		.text
			font-size: 27px
			font-family: MontHeavy, serif

		.arrow
			max-height: 1.5rem

		&.hidden
			display: none
//endregion
//region Buttons
.button
	background: none
	color: white

	width: fit-content
	padding: .25rem 2rem

	border: 1px solid white
	border-radius: 999px

	cursor: pointer

	text-transform: uppercase

	text-decoration: none
//endregion
