@use './stylesheet/abstract' as *

body
	min-height: 20000px
	min-width: 100%
	background: black
	font-family: "MontRegular", serif
	
	#smooth-wrapper
		min-height: 20000px
		min-width: 100%
	
	#smooth-content
		position: relative
		width: 100%
		min-height: 20000px
		min-width: 100%
		transform: none !important
	
	#threejs_container
		position: relative
		height: 100%
		min-height: 20000px
		width: 100%
	
	#z_canvas_three_js
		position: fixed
		inset: 0
		max-height: 100vh
		
		.loading-bar
			position: absolute
			top: 50%
			width: 100%
			height: 2px
			background: white
			transform: scaleX(0.3)
			transform-origin: top left
			transition: transform 0.5s
			
			&.ended
				transform: scaleX(0)
				transform-origin: 100% 0
				transition: transform 1.5s ease-in-out
		
		.selected-project
			position: fixed
			inset: 0
			max-height: 100vh
			
			overflow: scroll
			padding: 100px 0
			
			background: rgba($gax-dark-grey, .9)
			
			display: flex
			justify-content: center
			
			z-index: 200
			
			.close-icon
				position: fixed
				top: 2rem
				right: calc((100vw - min(1400px, 80vw)) / 6)
				
				cursor: pointer
				
				width: auto
				aspect-ratio: 1
			
			.loading
				display: flex
				justify-content: center
				align-items: center
				
				color: white
				
				font-size: 3rem
				
				svg
					animation: loading-icon-rotation-loop 3s linear infinite
				
				
				@keyframes loading-icon-rotation-loop
					from
						transform: rotate(0deg)
					to
						transform: rotate(360deg)
			
			.content
				width: 100%
				max-width: min(1400px, 80vw)
				
				height: fit-content
				min-height: 100%
				
				display: flex
				gap: 0
				flex-direction: column
				position: relative
				
				.text
					padding: 6rem 2rem
					
					display: flex
					flex-direction: column
				
				div
					display: flex
					flex-direction: column
					gap: 0
				
				p
					display: flex
					flex-direction: column
					gap: 0
					padding: 0
					margin: 0
				
				img
					max-width: 100%
					width: 100%
					height: auto
					object-fit: contain
			
			@include zi-media
				padding: 4rem
				
				.close-icon
					top: 1rem
					right: 1rem
					max-height: 2rem
					stroke-width: 4px
				
				.content
					max-width: unset
			
			@include zi-media('<=', mobile)
				padding: 2rem
				
				.close-icon
					top: .5rem
					right: .5rem
					max-height: 1rem
					stroke-width: 4px
		
		.introduction
			color: white
			
			h1
				font-size: 2.5rem
				font-weight: 400
				
				text-transform: uppercase
				white-space: nowrap
				
				margin: 0
		
		.contact-us
			color: white
			
			width: fit-content
			
			display: flex
			flex-direction: column
			gap: 2rem
			
			h1
				font-size: 2.5rem
				font-weight: 400
				
				text-transform: uppercase
				white-space: nowrap
				
				margin: 0
			
			.call-to-action
				display: flex
				flex-direction: row
				gap: 2rem
				
				align-items: flex-end
				
				.button
					padding: 0
					white-space: nowrap
					
					position: relative
					
					border: none
					
					&:after
						content: ''
						position: absolute
						inset: auto 0 -.25rem
						width: 25%
						height: 1px
						background: white
						
						transition: width .3s ease
					
					&:hover
						&:after
							width: 100%
				
				.socials
					display: flex
					flex-direction: row
					gap: 1rem
		
		.project-information
			position: relative
			
			.content
				position: absolute
				inset: 0
				
				display: flex
				flex-direction: column
				color: white
				
				white-space: nowrap
			
			.title
				font-size: 1.5rem
			
			.description
